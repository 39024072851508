export const DEMO_NEWS_LISTs: any = [
    { id: 1, title: 'ประกันชั้น 1 อาจไม่เหมาะกับคุณค้นหาการเลือกประกันให้เหมาะสม', type: 'ประกันภัย', path: '/tmp/news1.png' },
    { id: 2, title: '10 วิธีการออมเงินสำหรับอนาคตเพื่อ คนที่คุณรัก', type: 'ประกันภัย', path: '/tmp/news2.png' },
    { id: 3, title: '4 วิธีการดูแลรถของคุณให้ใหม่เหมือนออกจากศูนย์', type: 'สาระความรู้ทั่วไป', path: '/tmp/news3.png' },
    { id: 4, title: 'ประกันชั้น 1 อาจไม่เหมาะกับคุณค้นหาการเลือกประกันให้เหมาะสม', type: 'ประกันภัย', path: '/tmp/news1.png' },
    { id: 5, title: '10 วิธีการออมเงินสำหรับอนาคตเพื่อ คนที่คุณรัก', type: 'ประกันภัย', path: '/tmp/news2.png' },
    { id: 6, title: '4 วิธีการดูแลรถของคุณให้ใหม่เหมือนออกจากศูนย์', type: 'สาระความรู้ทั่วไป', path: '/tmp/news3.png' },
    { id: 7, title: 'ประกันชั้น 1 อาจไม่เหมาะกับคุณค้นหาการเลือกประกันให้เหมาะสม', type: 'ประกันภัย', path: '/tmp/news1.png' },
    { id: 8, title: '10 วิธีการออมเงินสำหรับอนาคตเพื่อ คนที่คุณรัก', type: 'ประกันภัย', path: '/tmp/news2.png' },
    { id: 9, title: '4 วิธีการดูแลรถของคุณให้ใหม่เหมือนออกจากศูนย์', type: 'สาระความรู้ทั่วไป', path: '/tmp/news3.png' },
]

export const DEMO_PROMOTIONs = [
    {
        icon: 'piggy-bank',
        title: 'ผ่อนสูงสุด 20 งวด',
        detail: 'คุณสามารถผ่อนประกันรถยนต์ได้สูงสุดถึง 20 งวด ผ่อนเท่ากันทุกงวด ไม่ต้องใช้บัตรเครดิต/เดบิต จ่ายปุ๊ป คุ้มครองทันที 100%'
    },
    {
        icon: 'heart-circle',
        title: 'OBICARE',
        detail: 'เราขอเป็นคนที่ดูแลคุณเคียงข้างคุณตั้งแต่ต้นจนจบ ไม่ว่าจะเป็นเรื่องประกัน หรือจะเคลมประกัน ให้คุณตลอดปี พร้อมตอบปัญหาและคลายข้อสงสัย ครบจบเรื่องประกัน'
    },
    {
        icon: 'piggy-bank',
        title: 'ให้คุณเลือกได้กว่า 20 บริษัทประกันชั้นนำ',
        detail: 'ไม่ว่าจะเป็น บมจ.ทิพยประกันภัย, บมจ.เทเวศประกันภัย, บมจ.อลิอันซ์ อยุธยา ประกันภัย, บมจ.วิริยะประกันภัย, บมจ.คุ้มภัยโตเกียวมารีนประกันภัย, บมจ.เอ็ม เอส ไอ จี ประกันภัย (ประเทศไทย) และอื่นๆอีกมากมาย'
    },
]

export const DEMO_INSURANCE = {
    company: { name: 'ธนาชาตประกันภัย' },
    insuranceType: 'ประกันภัยชั้น 1',
    insurancePlace: { name: 'ซ่อมอู่' },

    moneyFund: 800000,
    moneyDeduct: 5000,
}

/*
                <div className={'_meta'}>{data.insuranceType} / {data.}</div>

    return <div className={'insurance-card'}>

        <hr/>

        <div className={'_detail'}>
            <span className={'_name'}>ทุนประกันรถ</span>
            <span className={'_value'}>800,000 บาท</span>
        </div>

        <div className={'_detail'}>
            <span className={'_name'}>ค่าเสียหายส่วนแรก</span>
            <span className={'_value'}>5,000 บาท</span>
        </div>

        <hr/>

        <div className={'_price'}>
            <div className={'_item-price'}>
                <span>ผ่อนราคา</span>
                <span className={'_text'}>พิเศษ 10 เดือน</span>
                <div className={'_price'}>1,450.00 บาท</div>
                <div className={'_text mt-3'}>สนใจแผนนี้</div>
            </div>

            <div className={'mx-1'}/>

            <div className={'_item-price'}>
                <span>ผ่อนราคา</span>
                <span className={'_text'}>จ่ายราคาพิเศษ</span>
                <div className={'_price'}>14,500.00 บาท</div>
                <div className={'_text mt-3'}>สนใจแผนนี้</div>
            </div>

        </div>

        <div className={'_more'}>ดูรายละเอียด</div>

    </div>*/