import * as React from 'react'
import { useState } from 'react'

import { date, get } from 'unno-comutils'
import { Icon } from 'unno-comutils/ui'

import { BodyHeader, Footer, FooterLink, Header } from '../components/layout'
import { DEMO_PROMOTIONs } from '../var.demo'
import { CustomerInterest } from '../components/customer'
import { META_COMPNAYs } from '../var'

Home.getInitialProps = async () => {
    return await get('web/home/init').then(({ ok, _time, ...d }: any) => d || [])
}

export default function Home (props: any) {
    //const [news, setNews] = useState<any>(props.news || [])
    //const [blogs, setBlogs] = useState<any>(props.blogs || [])
    const [reviews,] = useState<any>(props.reviews || [])
    //const [feqs, setFeqs] = useState<any>(props.feqs || [])

    // ----- RENDER

    return <>
        <Header/>

        <div className={'home-banner'}>
            <div className={'_bg'}/>
            <div className="home-container _container">
                <img className={'_car -bc'} src={'/static/home/car_1.png'} alt="Car 1"/>
                <img className={'_car -tr'} src={'/static/home/car_2.png'} alt="Car 2"/>

                <div className="_detail">
                    <div className={'home-banner-title -sm'}>ต้องการคำแนะนำ ?</div>
                    <div className={'home-banner-title'}>ให้ OBI ช่วยคุณ</div>

                    <div className={'home-banner-detail'}>ทุกเรื่องที่คุณสงสัยเกี่ยวกับการทำประกันรถยนต์ไม่ว่าจะเป็น รูปแบบของประกัน
                        เปรียบเทียบรวมไปถึงการให้คำแนะนำเพื่อให้คุณได้ประกันที่เหมาะสมกับคุณ มากที่สุด
                    </div>
                    <div className={'home-banner-detail'}>คุณสามารถใส่ชื่อและเบอร์โทรเพื่อให้ทางเราติดต่อกลับได้เลย</div>
                </div>
                <CustomerInterest/>
            </div>
        </div>

        <div className={'home-review-slide'}>
            <div className={'home-container'}>
                <BodyHeader className="c">รีวิวจากลูกค้าเรา</BodyHeader>
                <div className={'review-slide-items'}>
                    {reviews.map((d: any) => <div className={'_item'} key={'item_' + d.id}>
                        <div className="_avatar">
                            {d.photo?.url
                                ? <img src={d.photo?.url} className="_img" alt={'AVATAR'}/>
                                : <Icon name={'user'} className="_icon"/>}
                        </div>
                        <div className={'_title'}>{d.name}</div>
                        <div className={'_detail'}>{d.detail}</div>
                        <div className={'_time'}> {date(d.time, 'St')}</div>
                    </div>)}
                </div>
            </div>
        </div>

        <div className={'home-container'}>
            <BodyHeader className={'mt'}>ทำไมต้องซื้อประกันที่ OBI</BodyHeader>
            <div className={'home-insurance-promotion'}>
                {DEMO_PROMOTIONs.map((o: any, x: number) => <div key={'p_' + x} className={'_item'}>
                    <Icon name={o.icon} className="_icon"/>
                    <div className={'_title'}>{o.title}</div>
                    <div className={'_detail'}>{o.detail}</div>
                </div>)}
            </div>
        </div>

        <div className={'home-container'}>
            <BodyHeader>OBI มีประกันให้คุณเลือก</BodyHeader>
            <div className={'home-compony-list'}>
                {META_COMPNAYs.map((d: any, index: any) => <div className="_item" key={'item_' + index}>
                    <img src={d.logo} alt={d.name}/>
                </div>)}
            </div>
        </div>
        {/*
        <div className={'home-container -push_up'}>
            <BodyHeader>OBI แนะนำประกันยอดฮิต</BodyHeader>
            <div className={'home-insurance-popular'}>
                {[...new Array(4)].map((_: any, index: any) => <InsuranceCard key={'item_' + index} mini data={DEMO_INSURANCE}/>)}
            </div>
        </div>

        <div className={'home-container -push_up'}>
            <BodyHeader>โปรโมชั่นและข่าวสาร</BodyHeader>
            <div className={'home-news-list'}>
                {news.map((d: any) => <Link key={'item_' + d.id} href={'/news/' + d.id} className={'news-card'}>
                    <span className="_photo"><img src={d.photo?.url} alt="COVER"/></span>
                    <span className={'_title'}>{d.title}</span>
                </Link>)}
            </div>
        </div>

        <div className={'home-container -push_up'}>
            <BodyHeader>บทความที่น่าสนใจ</BodyHeader>
            <div className={'home-article-list'}>
                {blogs.map((d: any) => <Link key={'item_' + d.id} href={'/blog/' + d.id} className={'article-card'}>
                    <span className="_photo"><img src={d.photo?.url} alt="COVER"/></span>
                    <span className={'_tag'}>{d.title}</span>
                    <span className={'_title'}>{d.detail}</span>
                    <Icon name={'arrow-right'} className="_icon"/>
                </Link>)}
            </div>
        </div>
        <div className={'home-container -push_up'}>
            <BodyHeader className="c">คำถามที่พบบ่อย</BodyHeader>
            <div className={'home-feq-list'}>
                {feqs.map((d: any) => {
                    return <div key={'item_' + d.id} className={'_item'}>
                        <div className={'_question'}>
                            <div className="_text">{d.title}</div>
                            <Icon button name={d.view ? 'minus' : 'plus'} className="_icon"
                                  onClick={() => setFeqs((prev: any) => prev.map((d2: any) => d2.id === d.id ? ({ ...d2, view: !d2.view }) : d2))}/>
                        </div>
                        {d.view && <div className={'_answer'}>{d.detail}</div>}
                    </div>
                })}
            </div>
        </div>
        */}

        <Footer/>
        <FooterLink/>
    </>
}


